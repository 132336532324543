import React from 'react';

import { Attribution, TransparentErrorBoundary } from '@confluence/error-boundary';
import { expValEquals } from '@confluence/feature-experiments';

export const WhatsNewInConfluence = () => {
	return null;
};

export const WhatsNewInConfluenceContainer = () => {
	expValEquals('cc_onboarding_whats_new_in_conf_existing_aa', 'cohort', 'experiment') ||
		expValEquals('cc_onboarding_whats_new_in_conf_new_aa', 'cohort', 'experiment');

	if (
		expValEquals('cc_onboarding_whats_new_in_conf_new', 'cohort', 'experiment') ||
		expValEquals('cc_onboarding_whats_new_in_conf_existing', 'cohort', 'experiment')
	) {
		return <WhatsNewInConfluence />;
	}
	return null;
};

export const WhatsNewInConfluenceErrorBoundary = () => {
	return (
		<TransparentErrorBoundary attribution={Attribution.CC_ONBOARDING}>
			<WhatsNewInConfluenceContainer />
		</TransparentErrorBoundary>
	);
};
